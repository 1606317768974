import useBasicApi from '@/api/useBasicApi'
import { ServerEvent } from '@/enums'
import config from '@/config'
import idEndpointGetter from '@/api/idEndpointGetter'
import nearestNotExpiredApi from '@/api/modules/projects/bookings/nearestNotExpired'

const axios = config.urls.AXIOS_API

export default function bookingsApi(parentEndpoint = '') {
  const ENDPOINT = `${parentEndpoint}/bookings`

  return idEndpointGetter({
    ENDPOINT,
    CREATE_EVENT: ServerEvent.BOOKING_CREATED,
    DELETE_EVENT: ServerEvent.BOOKING_DELETED,
    UPDATE_EVENT: ServerEvent.BOOKING_UPDATED,
    create: async ({
      hardwareSetupId: hardware_setup_id,
      startDate: start_date,
      endDate: end_date,
      userId: user_id,
    }) => axios.post(`${ENDPOINT}/`, { hardware_setup_id, start_date, end_date, user_id }),
    ...useBasicApi(ENDPOINT, ['get', 'getNext']),
    nearestNotExpired: nearestNotExpiredApi(ENDPOINT),
    for: bookingId => {
      const BOOKING_ENDPOINT = `${ENDPOINT}/${bookingId}`
      return {
        ENDPOINT: BOOKING_ENDPOINT,
        ...useBasicApi(BOOKING_ENDPOINT, ['get', 'remove']),
        update: ({ startDate: start_date, endDate: end_date }) =>
          axios.patch(`${BOOKING_ENDPOINT}/`, { start_date, end_date }),
      }
    },
  })
}
